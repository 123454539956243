<template>
    <div class="work-bench-right">
        <div class="bench-content-wrapper" v-if="showBench">
            <div class="bench-head">
                <div class="bench-head-avatar">
                    <img :src="mineInfo.avatar" alt="">
                </div>
                <div class="bench-head-info">
                    <p  class="name text-overflow">{{mineInfo.nickname}}</p>
<!--                    <p class="account">抖音号：{{mineInfo.unique_id}}</p>-->
                    <p class="info">
                        <span v-if="mineInfo.gender">性别：{{mineInfo.gender==0?'未知':mineInfo.gender==1?'男':'女'}}</span>
                        <span v-if="mineInfo.birthday">生日：{{mineInfo.birthday}}</span>
                    </p>
                </div>
            </div>
<!--            <div class="bench-summary">-->
<!--                <div class="summary-left">简介：</div>-->
<!--                <div class="summary-content">{{mineInfo.signature}}</div>-->
<!--            </div>-->
            <div class="bench-summary">
                <div class="summary-left">地区：</div>
                <div class="summary-content">{{mineInfo.country}}.{{mineInfo.province}}.{{mineInfo.city}}</div>
            </div>
            <div class="bench-data-overview">
                <div class="data-title">数据概况</div>
                <div class="data-list">
                    <div class="data-item">
                        <div class="item-left">
                            <p class="item-hang1">主页访问数</p>
                            <p class="item-num big-green">{{mineInfo.follower_count}}</p>
                        </div>
                        <div class="item-right">
                            <p class="item-hang1">视频数量</p>
                            <p class="item-num" >{{mineInfo.aweme_count}}</p>
                        </div>
                    </div>
                    <div class="data-item">
                        <div class="item-left">
                            <p class="item-hang1">总点赞数</p>
                            <p class="item-num" >{{mineInfo.favoriting_count}}</p>
                        </div>
                        <div class="item-right">
                            <p class="item-hang1">总评论量</p>
                            <p class="item-num" v-if="mineInfo">{{mineInfo.comment_count}}</p>
                        </div>
                        <div class="item-left">
                            <p class="item-hang1">总转发量</p>
                            <p class="item-num" >{{mineInfo.new_share_count}}</p>
                        </div>
                        <div class="item-right">
                            <p class="item-hang1">播放量</p>
                            <p class="item-num" v-if="mineInfo">{{mineInfo.new_play_count}}</p>
                        </div>
                    </div>
                </div>
            </div>
<!--            <div class="bench-data-oper">-->
<!--                <div class="btn-air-32" v-if="bid > 0" @click="toList()">切换账号</div>-->
<!--            </div>-->
        </div>
        <div class="bench-nodata-wrapper" v-else>
            <div class="bench-nodata-img">
                <img src="../../assets/img/image/img_wuzhanghao.png" alt="">
            </div>
            <p class="bench-nodata-text">{{approvalStatus != ''?approvalStatus:"您还没有账号，快去添加吧"}}</p>
            <div class="btn-grablue-32" v-if="approvalStatus == ''" @click="addAccountWork()">
               <i class="iconfont">&#xe60b;</i>添加账号
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                bid: 0,
                showBench: false,
                showCurrentBtn: true,
                // isShow: false,
                approvalStatus: '',//审核状态
                mineInfo: {},
            }
        },
        mounted() {
            //获取抖音置顶账号信息
            // this.$httpStudent.axiosGet(this.$api.tikhoknew_manage_index, res => {
            //     if (res.code === 200) {
            //         this.bid =res.data.id
            //         this.mineInfo = res.data;
            //         // 显示用户基本信息
            //         this.showBench = true;
            //     } else if (res.code === 204) {
            //         // 保存审核状态
            //         this.approvalStatus = res.message;
            //         this.showBench = false;
            //     } else if (res.code === 400) {
            //         this.approvalStatus = res.message;
            //         // 隐藏用户基本信息
            //         this.showBench = false;
            //         if (res.message ==='当前账号不存在！'){
            //             this.approvalStatus = ''
            //         }
            //     }
            //     else {
            //         this.showBench = false;
            //         this.$message({
            //             type: "error",
            //             message: res.message,
            //             duration: 1000
            //         });
            //     }
            // })
        },
        methods: {
            addAccountWork() {
                this.$router.push('/student/account/index')
            },
            toList() {
                this.$router.push('/student/account/index')
            },
            getAccountMine(type,platform_type,id){
                let param = {
                    type:type,
                    platform_type:platform_type,
                    id:id
                }
                this.$httpStudent.axiosGetBy(this.$api.get_account,param, res => {
                    if (res.code === 200) {
                        this.bid =res.data.id
                        this.mineInfo = res.data;
                        // 显示用户基本信息
                        this.showBench = true;
                    } else if (res.code === 204) {
                        // 保存审核状态
                        this.approvalStatus = res.message;
                        this.showBench = false;
                    } else if (res.code === 400) {
                        this.approvalStatus = res.message;
                        // 隐藏用户基本信息
                        this.showBench = false;
                        if (res.message ==='当前账号不存在！'){
                            this.approvalStatus = ''
                        }
                    }
                    else {
                        this.showBench = false;
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                })
            }

        }
    }
</script>

<style scoped lang="scss">
    .work-bench-right {
        width: 360px;
        background: #fff;
        border-radius: 10px;
        margin-left: 20px;
        .bench-content-wrapper {
            padding: 15px;
            .bench-head {
                display: flex;
                align-items: center;
                margin-top: 15px;
            }
            .bench-head-avatar {
                width: 90px;
                height: 90px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .bench-head-info {
                width: 1%;
                flex: 1;
                line-height: 1;
                margin-left: 15px;
                color: #999;
                font-size: 14px;
                .name {
                    color: #333;
                    font-size: 16px;
                    &:hover {
                        color: #20C997;
                    }
                }
                .account {
                    margin-top: 10px;
                }
                .info {
                    margin-top: 10px;
                    span {
                        display: inline-block;
                        margin-right: 6px;
                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }

            }
            .bench-summary {
                margin-top: 20px;
                display: flex;
                .summary-content {
                    width: 1%;
                    flex: 1;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
            }
            .bench-ranking {
                margin-top: 20px;
                .ranking-item {
                    display: inline-flex;
                    align-items: center;
                    background: #FF1616;
                    color: #fff;
                    height: 24px;
                    line-height: 24px;
                    border-radius: 12px;
                    padding: 0 15px 0 2px;
                    margin-bottom: 10px;
                    &:last-of-type {
                        margin-bottom: 0;
                        background: #FFB624;
                    }
                    .rank-icon {
                        width: 20px;
                        height: 20px;
                        -webkit-border-radius: 50%;
                        -moz-border-radius: 50%;
                        border-radius: 50%;
                        overflow: hidden;
                        background: #fff url("../../assets/img/image/wb-hg-icon.png") no-repeat center;
                    }
                    .text {
                        margin-left: 10px;
                        .num {
                            margin: 0 4px;
                        }

                    }
                }
            }
            .bench-data-overview {
                margin-top: 40px;
                .data-title {
                    font-size: 16px;
                }
            }
            .data-list {
                color: #999;
                .data-item {
                    display: flex;
                    flex-wrap: wrap;
                    &:first-of-type {
                        padding-bottom: 15px;
                        border-bottom: 1px solid #ededed;
                        .item-num {
                            font-size: 20px;
                        }
                    }
                }
                .item-left {
                    width: 50%;
                    margin-top: 15px;
                }
                .item-right {
                    width: 50%;
                    margin-top: 15px;
                    text-align: right;
                }
                .item-num {
                    font-size: 16px;
                    color: #333;
                }
            }
            .bench-data-oper {
                margin-top: 40px;
                text-align: center;
            }

        }
        .bench-nodata-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 100px;
            .bench-nodata-img {
                width: 200px;
                img {
                    max-width: 100%;
                }
            }
            .bench-nodata-text {
                margin: 30px 0 40px;
                color: #666;
                font-size: 14px;
            }
        }
        .big-green {
            color: #20C997;
        }
    }
</style>